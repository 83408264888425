<template>
	<div>
		<div class="box" v-loading.fullscreen.lock="fullscreenLoading">
			<Title title="我的工单" />
			<div class="ct_box">
				<div class="search-status_box flex">
					<div class="search_box flex">
						<el-date-picker value-format="timestamp" @change="timerFn" v-model="time" type="daterange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>

						<div class="order_search flex">
							<img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
							<el-input class="order_search_inp" v-model="orderId" placeholder="输入标题/订单号"></el-input>
							<button class="search_but" @click="getList">搜索</button>
						</div>
						<el-select v-model="resource.title" @change="ClassNum" placeholder="请选择媒体类别">
							<el-option v-for="item in resourceList" :key="item.id" :value="item.title">
							</el-option>
						</el-select>
					</div>
					<ul class="status_ul flex">
						<li :class="status.title == item.title ? 'active' : ''" v-for="(item, index) in statusUl"
							:key="index" @click="statusShow(item)">
							{{ item.title }}
						</li>
					</ul>
				</div>
				<table class="table2">
					<thead>
						<tr>
							<td>订单ID</td>
							<td>标题</td>
							<td>问题描述</td>
							<td>媒介名称</td>
							<td>金额</td>
							<td>状态</td>
							<td>提交时间</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="item.id">
							<td>{{ item.sn }}</td>
							<td>
								<td_remark :text="item.title" />
							</td>
							<td>{{ item.desc }}</td>
							<td>
								{{ item.medium_title }}
							</td>
							<td>￥{{ item.order_price }}</td>
							<td>
								<p class="untreated" v-if="item.status == 0">
									未处理
								</p>
								<p class="untreated_success" v-if="item.status == 1">
									已处理
								</p>
								<p class="untreated_refuse" v-if="item.status == 2">
									拒绝处理
								</p>
							</td>
							<td>
								{{ item.createtime }}
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>

	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { timeCut } from '../../util/timeCut'
export default {
	components: {
		Title,
	},
	mounted() {
		this.$store.commit('childTab', '我的工单')
		if (this.$route.query.status && this.$route.query.status == '已处理') {
			this.status = {
				id: 1,
				title: '已处理',
			}
		}
		this.curlGet('/api/medium_category/list').then(res => {
			if (res.data.code) {
				this.resourceList = res.data.data
			}
		})
		this.curlGet('/api/package/list').then(res => {
			if (res.data.code) {
				this.packageList = res.data.data
			}
		})
		this.getList()
	},
	data() {
		return {
			time: '',
			resource: {
				id: 0,
				title: '不限'
			},
			orderId: '',
			resourceList: [],
			packageList: [],
			statusUl: [{
				id: '',
				title: '全部'
			}, {
				id: 0,
				title: '未处理'
			}, {
				id: 1,
				title: '已处理',
			},
			{
				id: 2,
				title: '拒绝处理',
			}
			],
			status: {
				id: '',
				title: '全部'
			},
			tableData: [],
			total_page: 0, //分页总页数
			page: 1,
			count:0,
			fullscreenLoading: false, //loding
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.getList()
		},
		statusShow(item) {
			if (item.title == '全部') {
				this.status = {
					id: '',
					title: '全部'
				},
					this.time = '',
					this.orderId = ''
				this.resource = {
					id: 0,
					title: '不限'
				}
			} else if (item.title == '未处理') {
				this.status = {
					id: 0,
					title: '未处理'
				}
			} else if (item.title == '已处理') {
				this.status = {
					id: 1,
					title: '已处理'
				}
			} else {
				this.status = {
					id: 2,
					title: '拒绝处理'
				}
			}
			this.getList()
		},
		LinkShow() {
			this.Link = true
		},
		wordOrderShow() {
			this.WorkOrder = true
		},
		subFn() {

		},
		copy() {
			//参数是复制的内容
			//.then()中的函数是复制成功和复制失败的两个回调函数
			this.$copyText(this.status).then(
				(e) => {
					return this.$message({
						message: '复制成功',
						type: 'success',
					})
				},
				(err) => {
					return this.$message({
						message: '复制失败',
						type: 'warning',
					})
				}
			)
		},
		getList() {
			this.fullscreenLoading = true
			// 取媒体类别

			let data = {}
			if (this.status.id !== '') {
				data.status = this.status.id
			}
			if (this.time) {
				data.start_time = this.time[0] / 1000;
				data.end_time = this.time[1] / 1000;
			}
			if (this.resource.id != '') {
				data.category_id = this.resource.id
			}
			if (this.orderId !== '') {
				data.search = this.orderId
			}
			this.curlGet('/api/users/work', data).then(res => {
				if (res.data.code) {
					console.log(res.data);
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
					this.tableData.forEach((item, index) => {
						this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
						console.log(this.tableData[index]);
					})
					this.fullscreenLoading = false
				}
			})
		},
		// 切换页
		currentTab(val) {
			console.log('切换');
			this.page = val
			this.ListFn()
		},
		// 上一页
		prevClick() {
			console.log('up');
			this.page -= 1
			this.ListFn()
		},
		// 下一页
		// next-click
		nextClick() {
			console.log('down');
			this.page += 1
			this.ListFn()
		},
		// 选中日期后的回调
		timerFn() {
			this.getList()

		},
		ClassNum(val) {
			let resultArr = this.resourceList.filter((item, key) => {
				if (item.title == val) {
					this.resource.id = item.id
					this.resource.title = item.title
				}
			});
			this.getList()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/MediaOrder.scss';
</style>